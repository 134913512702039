import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/usr/src/app/workspaces/extie/src/templates/help-layout.tsx";
import Step1Video from "src/videos/tutorials/getting-started/287b83af/Step1-converted.mp4";
import Step2Video from "src/videos/tutorials/getting-started/287b83af/Step2-converted.mp4";
import Step3Video from "src/videos/tutorials/getting-started/287b83af/Step3-converted.mp4";
import Step4Video from "src/videos/tutorials/getting-started/287b83af/Step4-converted.mp4";
import Step5Video from "src/videos/tutorials/getting-started/287b83af/Step5-converted.mp4";
import Step6Video from "src/videos/tutorials/getting-started/287b83af/Step6-converted.mp4";
import Step7Video from "src/videos/tutorials/getting-started/287b83af/Step7-converted.mp4";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Box = makeShortcode("Box");
const YouTube = makeShortcode("YouTube");
const VideoLoop = makeShortcode("VideoLoop");
const Message = makeShortcode("Message");
const AddCommandTag = makeShortcode("AddCommandTag");
const GetGlobalTag = makeShortcode("GetGlobalTag");
const Global = makeShortcode("Global");
const SetGlobalTag = makeShortcode("SetGlobalTag");
const IfTag = makeShortcode("IfTag");
const EndIfTag = makeShortcode("EndIfTag");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Getting  Started`}</h1>
    <Box align="center" mb="20px" mdxType="Box">
  <YouTube videoId="BYMGrKr-q_0" mdxType="YouTube" />
    </Box>
    <p>{`The Wrdie editor is easy to use but incredibly powerful.  In this tutorial you will create a story and get an introduction to the editor UI.`}</p>
    <h3>{`Step 1. Make a new story`}</h3>
    <VideoLoop width="640px" source={Step1Video} mdxType="VideoLoop" />
    <blockquote>
      <p parentName="blockquote">{`From the Create Page or the Home Page, click the button that says `}<strong parentName="p">{`Start a new story`}</strong>{`.  Name your story `}<inlineCode parentName="p">{`Tutorial Quiz`}</inlineCode>{`, and then click `}<strong parentName="p">{`Save`}</strong>{`.`}</p>
    </blockquote>
    <p>{`The Wrdie editor opens to the story overview form.  From here you can set the basic details of your story like its name, description, and image.`}</p>
    <p>{`You can always access the story overview form by clicking on your story's name at the top left.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Give your story a description, something like `}<inlineCode parentName="p">{`this is for learning`}</inlineCode>{`, leave everything else as is and scroll to the bottom and click `}<strong parentName="p">{`Save Story`}</strong>{`.`}</p>
    </blockquote>
    <h3>{`Step 2. Write the INIT message and test`}</h3>
    <VideoLoop width="640px" source={Step2Video} mdxType="VideoLoop" />
    <p>{`Saving your story has also created a new message called `}<Message mdxType="Message">{`INIT`}</Message>{`. This is where your story will start.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Click the `}<Message mdxType="Message">{`INIT`}</Message>{` message on the left.`}</p>
    </blockquote>
    <p>{`Clicking on a message opens up the editor for that message. Messages have a name and text.  The name identifies the message and must be unique to the story. The text is what will appear on screen when the message runs.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Type `}<inlineCode parentName="p">{`hello world`}</inlineCode>{` into the message text and click `}<strong parentName="p">{`Save message.`}</strong></p>
    </blockquote>
    <h3>{`Step 3. Test`}</h3>
    <VideoLoop width="640px" source={Step3Video} mdxType="VideoLoop" />
    <p>{`You can test your story at any time to see it from the perspective of a player. The test form allows you to edit your data and start from anywhere in your story. `}</p>
    <blockquote>
      <p parentName="blockquote">{`Click `}<strong parentName="p">{`Test Story`}</strong>{` in the upper-left. Leave all of the fields blank and click `}<strong parentName="p">{`Play`}</strong>{`.`}</p>
    </blockquote>
    <p>{`You should see 'hello world' print out on the screen. This is because Wrdie ran your `}<Message mdxType="Message">{`INIT`}</Message>{` message and sent the result back to you.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Click the back arrow next to the gears on the top bar to go back to the editor.`}</p>
    </blockquote>
    <h3>{`Step 4. Add commands`}</h3>
    <VideoLoop width="640px" source={Step4Video} mdxType="VideoLoop" />
    <p>{`Commands create choices for the player. Every command is a combination of text and a message name.`}</p>
    <p>{`The `}<AddCommandTag mdxType="AddCommandTag" />{` tag, placed anywhere in the text, will add a new command for the player to select. `}</p>
    <p>{`Tags are the little pills you see in the toolbar. To add a tag, drag it from the toolbar into your text. Once added, tags can be dragged around inside your text.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Add the text `}<inlineCode parentName="p">{`What does paradox mean?`}</inlineCode>{` to the INIT message. Then from the toolbar, drag and drop `}<AddCommandTag mdxType="AddCommandTag" />{` into the message text. The tag should appear in the text and you should be able to drag it around. (There is currently a bug where sometimes the tag appears as the text "{"type":"addCommand"}".  If this happens, just delete the text and try dragging the tag in again).`}</p>
    </blockquote>
    <p>{`When Wrdie runs your message, it evaluates the tags. The `}<AddCommandTag mdxType="AddCommandTag" />{` tag will create a new command for the player when it is evaluated. The text of the command, and what message it runs, can be set by editing the tag.`}</p>
    <p>{`To edit a tag, click its edit icon. The tag will change into its editing mode allowing you to modify it. When you are done, click the checkmark icon to save your changes.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Click the edit icon in the `}<AddCommandTag mdxType="AddCommandTag" />{` tag. Click the edit icon next to the command text and type `}<inlineCode parentName="p">{`Lost socks`}</inlineCode>{` into the modal and click `}<strong parentName="p">{`Save`}</strong>{`. Then in the dropdown, select `}<inlineCode parentName="p">{`New Message`}</inlineCode>{`  and call your new message `}<inlineCode parentName="p">{`LOST-SOCKS`}</inlineCode>{`. Click the checkmark and then click `}<strong parentName="p">{`Save Message`}</strong>{`. `}</p>
    </blockquote>
    <p>{`The command text is the text that will appear in the button for the player to choose. The message name is the message that will run when the player clicks that button.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Drag in another `}<AddCommandTag mdxType="AddCommandTag" />{` tag. Set the command text to `}<inlineCode parentName="p">{`A contradictory statement`}</inlineCode>{` and create a new message named `}<inlineCode parentName="p">{`CONTRADICT-STATEMENT`}</inlineCode>{`. Click `}<strong parentName="p">{`Save Message`}</strong>{`. Click `}<strong parentName="p">{`Test Story`}</strong>{` and you should see your two commands at the bottom of the screen.`}</p>
    </blockquote>
    <p>{`The commands are added in the order that the tags appear. You can drag the tags around to change the order that they appear in while playing.`}</p>
    <h3>{`Step 5. Write the next messages`}</h3>
    <VideoLoop width="640px" source={Step5Video} mdxType="VideoLoop" />
    <p>{`  The messages you connected to your commands do not have any text yet. This is why nothing appeared on the screen when you clicked them.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Click on the `}<Message mdxType="Message">{`LOST-SOCKS`}</Message>{` message.  Add some appropriate text like `}<inlineCode parentName="p">{`That's incorrect.`}</inlineCode>{` and save.  Do the same for the `}<Message mdxType="Message">{`CONTRADICT-STATEMENT`}</Message>{` message with something like `}<inlineCode parentName="p">{`That's right!`}</inlineCode>{` and save. Click `}<strong parentName="p">{`Test Story`}</strong>{` in the upper left. Clicking on one of the commands should now show the appropriate text.`}</p>
    </blockquote>
    <p>{`Messages and commands are fundamental to adding interactivity to your stories. You can easily build a choose-your-own-adventure type story just by creating messages, that add commands, that run messages, that add commands, that run messages, and so on.`}</p>
    <p>{`One thing to be aware of is that, by default, all of the commands are removed after the user has selected one. This can leave your player stuck. Proper testing can help prevent such situations.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Add an `}<AddCommandTag mdxType="AddCommandTag" />{` tag to the `}<Message mdxType="Message">{`LOST-SOCKS`}</Message>{` message. Set its text to `}<inlineCode parentName="p">{`Try again`}</inlineCode>{` and select `}<inlineCode parentName="p">{`INIT`}</inlineCode>{` as the message. Do the same for the `}<Message mdxType="Message">{`CONTRADICT-STATEMENT`}</Message>{` message and set the command text to `}<inlineCode parentName="p">{`Go Back`}</inlineCode>{` (don't forget to save). Click `}<strong parentName="p">{`Test Story`}</strong>{` again and now you should be able to move between the 3 messages.`}</p>
    </blockquote>
    <h3>{`Step 6. Add a field and some globals`}</h3>
    <VideoLoop width="640px" source={Step6Video} mdxType="VideoLoop" />
    <p>{`The main character of an interactive story is often the player themself. By letting the player add their own details, such as name and pronouns, you can further increase the emotional connection the players will feel.  `}</p>
    <p>{`In Wrdie, fields are a chance for the player to put in their own data before the story begins. All of your story's fields will be part of a form that the player can fill out before they start.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Click on your story's name in the upper left to go back to the overview form. Click `}<strong parentName="p">{`Add Field`}</strong>{` to add a new field. Make the name `}<inlineCode parentName="p">{`First Name`}</inlineCode>{` and leave the type as `}<inlineCode parentName="p">{`String`}</inlineCode>{`. Click `}<strong parentName="p">{`Save Story`}</strong>{`.`}</p>
    </blockquote>
    <p>{`The player's entries are stored in a global (short for global variable). Globals allow you to set, modify, and retrieve data in your story text.`}</p>
    <p>{`The field you added created a global with the same name (minus any spaces). We can use the `}<GetGlobalTag mdxType="GetGlobalTag" />{` tag to include this data in our story. When the text is shown the player, your `}<GetGlobalTag mdxType="GetGlobalTag" />{` tag will be replaced by the current value of the selected global.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Click on `}<Message mdxType="Message">{`INIT`}</Message>{` to open it in the editor.  Drag a `}<GetGlobalTag mdxType="GetGlobalTag" />{` tag into the message's text. Edit the tag and select the `}<Global mdxType="Global">{`FirstName`}</Global>{` global (probably was already selected by default). Delete `}<inlineCode parentName="p">{`world`}</inlineCode>{` and replace it with your `}<GetGlobalTag mdxType="GetGlobalTag" />{`. Save your message and click `}<strong parentName="p">{`Test Story`}</strong>{`.  There should now be a field for `}<inlineCode parentName="p">{`FirstName`}</inlineCode>{` in the test story form. Enter your name in the field and click `}<strong parentName="p">{`Play`}</strong>{`. The `}<GetGlobalTag mdxType="GetGlobalTag" />{` tag should be replaced with your name.`}</p>
    </blockquote>
    <p>{`Globals from fields are set automatically to what was entered by the player. `}</p>
    <p>{`Globals can also be set by using the `}<SetGlobalTag mdxType="SetGlobalTag" />{` tag. Globals can have different types which help restrict the kind of data that they store.`}</p>
    <p>{`Strings hold text. Integers hold numbers. Booleans hold either true or false. Custom globals hold one of a restricted set of options.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Edit the `}<Message mdxType="Message">{`LEFT`}</Message>{` message. Drag a `}<SetGlobalTag mdxType="SetGlobalTag" />{` tag into the message text and click its edit icon. Select `}<inlineCode parentName="p">{`New Global...`}</inlineCode>{` and name the global `}<inlineCode parentName="p">{`LastAnswer`}</inlineCode>{`. Set the type to `}<inlineCode parentName="p">{`Custom`}</inlineCode>{`. In the entry below, type in `}<inlineCode parentName="p">{`Right`}</inlineCode>{` and hit tab to create the Right option. Type in `}<inlineCode parentName="p">{`Wrong`}</inlineCode>{` and hit tab again to create the Wrong option. Delete the default `}<inlineCode parentName="p">{`Custom1`}</inlineCode>{` option and click `}<strong parentName="p">{`Save`}</strong>{`.`}</p>
    </blockquote>
    <p>{`When using a Custom type, the editor will only let you set the global to one of the possible values. These can be useful for maintaining state that changes between different possibilities.  `}</p>
    <blockquote>
      <p parentName="blockquote">{`In the `}<Message mdxType="Message">{`LOST-SOCKS`}</Message>{` message, edit the `}<SetGlobalTag mdxType="SetGlobalTag" />{` tag to set `}<Global mdxType="Global">{`LastAnswer`}</Global>{` to `}<inlineCode parentName="p">{`Wrong`}</inlineCode>{`. Then save the message and add a `}<SetGlobalTag mdxType="SetGlobalTag" />{` tag to the `}<Message mdxType="Message">{`CONTRADICT-STATEMENT`}</Message>{` message that sets it to `}<inlineCode parentName="p">{`Right`}</inlineCode>{`. Finally, go into the `}<Message mdxType="Message">{`INIT`}</Message>{` message and add some text like `}<inlineCode parentName="p">{`Last answer was: `}</inlineCode>{` and a new `}<GetGlobalTag mdxType="GetGlobalTag" />{` to print out the value of `}<Global mdxType="Global">{`LastAnswer`}</Global>{`. Save and test your story and you should see the direction you chose every time you go back. `}</p>
    </blockquote>
    <h3>{`Step 7. Use an if`}</h3>
    <VideoLoop width="640px" source={Step7Video} mdxType="VideoLoop" />
    <p>{`While printing the value of globals is cool enough, the real power comes from being able to make decisions based on their current value. The `}<IfTag mdxType="IfTag" />{` and `}<EndIfTag mdxType="EndIfTag" />{` tags will only evaluate the text inside if its condition is true.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Click on the `}<Global mdxType="Global">{`LastAnswer`}</Global>{` global in the list on the left. Add `}<inlineCode parentName="p">{`Unanswered`}</inlineCode>{` as another option and click `}<strong parentName="p">{`Save`}</strong>{`. Drag an `}<IfTag mdxType="IfTag" />{` tag into the `}<Message mdxType="Message">{`INIT`}</Message>{` message text before the text you entered in the last step. Edit its condition to check that `}<inlineCode parentName="p">{`LastAnswer !== Unanswered`}</inlineCode>{` (!== means doesn't equal). Drag in an `}<EndIfTag mdxType="EndIfTag" />{` tag. Make sure the text is in between the `}<IfTag mdxType="IfTag" />{` and `}<EndIfTag mdxType="EndIfTag" />{` tags. Save and click `}<strong parentName="p">{`Test Story`}</strong>{`. Select `}<inlineCode parentName="p">{`Unanswered`}</inlineCode>{` in the form for the `}<Global mdxType="Global">{`LastAnswer`}</Global>{`. The `}<inlineCode parentName="p">{`Last answer was`}</inlineCode>{` text should only show up after you've picked an answer.`}</p>
    </blockquote>
    <p>{`Every `}<IfTag mdxType="IfTag" />{` tag requires an `}<EndIfTag mdxType="EndIfTag" />{` tag after it or else the message will not run. Whatever is in between them will only be evaluated if the condition on the `}<IfTag mdxType="IfTag" />{` tag is true. This includes tags.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Drag in  new `}<IfTag mdxType="IfTag" />{`, `}<EndIfTag mdxType="EndIfTag" />{`, and `}<AddCommandTag mdxType="AddCommandTag" />{`  tags.  Position `}<AddCommandTag mdxType="AddCommandTag" />{` in between the `}<IfTag mdxType="IfTag" />{` and `}<EndIfTag mdxType="EndIfTag" />{`. Set the if condition to `}<inlineCode parentName="p">{`LastAnswer === Right`}</inlineCode>{`. Make the command text `}<inlineCode parentName="p">{`End`}</inlineCode>{` and create a new message called `}<Message mdxType="Message">{`GOODBYE`}</Message>{`. Save `}<Message mdxType="Message">{`INIT`}</Message>{` and then go edit `}<Message mdxType="Message">{`End`}</Message>{` and add some kind of parting text. Click `}<strong parentName="p">{`Test Story`}</strong>{`. Goodbye should only appear as an option after you've gone left.`}</p>
    </blockquote>
    <h3>{`Wrapping up`}</h3>
    <p>{`You now have the basic understanding of how to make an interactive story with Wrdie. With just the knowledge of messages, commands, globals, and ifs, you can create complex branching stories that are personalized to your players with outcomes based on their decisions.`}</p>
    <p>{`If you'd like to continue learning about the Wrdie editor, a good next step is the `}<a parentName="p" {...{
        "href": "/help/locations-and-exits"
      }}>{`Locations and Exits`}</a>{` tutorial.`}</p>
    <p><a parentName="p" {...{
        "href": "/help"
      }}>{`Back to help`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      